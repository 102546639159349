/*
Script by : Clem
---------------------------------------
||              JS Menu              ||
---------------------------------------

SUR LES BOUTONS : mettre la classe "js-menu-button", mettre en href un anchor de son choix, attribut js-menu-group="nom_du_groupe".
SUR LES CONTENT : mettre la classe "jm-menu-content", mettre une classe content_hrefprecedent, attribut js-menu-group="nom_du_groupe".
CSS : il faut qu'une classe "hidden" soit déclarée avec un display:none.
CSS : les "a" des boutons prennent la classe "active" quand cliqués, utiliser a.active pour styliser le bouton actuellement actif.
Rien à modifier dans ce fichier.
*/


$(document).ready(function() {

	// INITIALISATION
	/*base_year = $('.select_year').val();
	console.log(base_year);
	$('.year_container').each(function() {
		$(this).removeClass('active');
	});
	var selected_year = '.year_'+base_year;
	$(selected_year).addClass('active');*/

	// ON CHANGE
	$(".select_year").change(function() {
		selected_year = $(this).val();

		$('.year_container').each(function() {
			$(this).removeClass('active');
		});

		updateQueryStringParam('annee', selected_year);

		var selected_year = '.year_'+selected_year;
		$(selected_year).addClass('active');
	});

});