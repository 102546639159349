/*
Script by : Clem
---------------------------------------
||             Countdown             ||
---------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/

steps = 10000;
duration = (1*3600 + 5*60 + 39) * 1000 / 60;

if ( $('.compteur').length ) {

	var start = new Date("June 4, 2019 00:00:00").getTime();
	var end = new Date("June 4, 2019 01:05:39").getTime();

	distance = end - start;
	step_quantity = distance / steps;
	step_time = duration / steps;
	nb_step = 1;
	var x = setInterval(function() {

		// Déterminer le compte à rebours pour le DÉPART
		current = nb_step * step_quantity;
		var days = Math.floor(current / (1000 * 60 * 60 * 24));
		var hours = Math.floor((current % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		hours = ('0' + hours).slice(-2);
		var minutes = Math.floor((current % (1000 * 60 * 60)) / (1000 * 60));
		minutes = ('0' + minutes).slice(-2);
		var seconds = Math.floor((current % (1000 * 60)) / 1000);
		seconds = ("0" + seconds).slice(-2);

		$('.compteur').html(hours + ":" + minutes + ":" + seconds);

		// If the count down is finished, write some text
		if (nb_step > steps) {
			clearInterval(x);
			$('.compteur').html('01:05:39');
		}

		nb_step++;

	}, step_time);

}