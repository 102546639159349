
$(document).ready(function() {

	$('#tableau_resultats .bouton').click(function() {
		data_eventid = $(this).attr('data-eventid');
		data_eventname = $(this).attr('data-eventname');
		data_filetype = $(this).attr('data-filetype');
		data_filetypelow = data_filetype.toLowerCase();

		$('#modal_fichiers .event_name').html(data_eventname);
		$('#modal_fichiers .explications').addClass('hidden');
		$('#modal_fichiers .explications.'+data_filetypelow).removeClass('hidden');
		$('#modal_fichiers input[name=event_id]').val(data_eventid);
		$('#modal_fichiers input[name=filetype]').val(data_filetype);
	});
});