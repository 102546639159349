
$(document).ready(function() {

	$('.delete_entry').click(function() {
		
		delete_table = $(this).attr('delete-table');
		delete_column = $(this).attr('delete-column');
		delete_id = $(this).attr('delete-id');
		current_form = $(this).attr('current-form');

		form_line = $(this).closest('tr');

		if(current_form == 'users') { php_file = 'ajax_delete_user'; }
		else if(current_form == 'autres') { php_file = 'ajax_delete_autre'; }
		else { php_file = 'ajax_delete_entry'; }

		$.ajax({
			type: 'GET',
			url: '/admin/php/'+php_file+'.php?delete_table='+delete_table+'&delete_column='+delete_column+'&delete_id='+delete_id,
			success: function(data) {
				form_line.remove();
				if(debug_mode == true) { 
					console.log('L\'entrée suivante a été supprimée : delete_table = '+delete_table+', delete_column = '+delete_column+', delete_id = '+delete_id); 
				} 
			},
			error: function() {
				if(debug_mode == true) { 
					console.log('Echec lors de la suppression de l\'entrée : delete_table = '+delete_table+', delete_column = '+delete_column+', delete_id = '+delete_id); 
				} 
			}
		});


	});
});