/*
Script by : Clem
-------------------------------------------
||          Formulaires contact          ||
-------------------------------------------

Récupère les inputs du formulaire depuis _settings.php, vérifie le contenu des champs 
et les valide/invalide, envoi la requete AJAX de traitement des contacts.
Rien à modifier dans ce fichier.
*/

$(function() {

    // ------------------------------ SOUMISSION DU FORMULAIRE ------------------------------
    $(document).off('submit');
    $(document).on('submit', '.form_contact', function(e) {

        e.preventDefault(); // Empêcher la soumission du formulaire

        // Récupération des l'objet du form, son id, et l'objet de l'error box
        var $form_object = $(this);
        form_id = $form_object.attr('id');
        error_box = $form_object.find('.form_error_box');

        if(debug_mode) { console.log('Le formulaire "#'+form_id+'" a été submit'); }

        // Définition des regex de vérification des champs
        regex_texte = '.+';
        regex_email = '^.+@.+\\..+';
        regex_telephone = '^\\+?\\s*(\\d+\\s?){8,}$';

        // Conversion des données du form en array
        var data = $(this).serializeArray();

        // Ajout des valeurs des checkbox aux données envoyées au php par l'AJAX
        function contactform_add_checkbox() {
            contact_forms.forEach(function(contact_form) {
                if (contact_form['id'] == form_id) {
                    contact_form['inputs'].forEach(function(input) {
                        if(input['type'] == 'checkbox') { 
                            input_id = '#'+input['name'];
                            value = $(input_id).prop('checked');
                            nom_variable = input['name'];
                            window[''+nom_variable] = value;
                            if(debug_mode) { console.log('Ajout donnée checkbox : '+nom_variable+' = '+value); }

                            // Ajout des checkbox cochées au array des données du form
                            data.push({name: nom_variable, value: value});
                        }
                    });
                }
            });
        }
        contactform_add_checkbox();
        
        // Vérification des champs au submit
        function contactform_check_values() {
            contact_forms.forEach(function(contact_form) {

                if (contact_form['id'] == form_id) {

                    success_redirect_to = contact_form['success_redirect_to'];
                    success_message = contact_form['success_message'];
                    fail_message = contact_form['fail_message'];

                    nb_errors = 0;
                    nb_empty_required = 0;
                    list_errors = '';
                    list_empty_required = '';

                    contact_form['inputs'].forEach(function(input) {

                        if(input['type'] == 'text' || input['type'] == 'textarea' || input['type'] == 'email' || input['type'] == 'tel') { 
                            input_id = '#'+input['name'];
                            value = $form_object.find(input_id).val();

                            // Si l'input est requis et vide, nb_empty + 1
                            if(value == '' && input['required'] == 1) {
                                nb_empty_required++; 
                                if(list_empty_required == '') {
                                    list_empty_required = input['display_name'];
                                } else {
                                    list_empty_required += ', '+input['display_name'];
                                }
                            }

                            if(input['type'] == 'text' || input['type'] == 'textarea') {
                                var regex = new RegExp(regex_texte);
                                var resultat = regex.test(value);
                            }
                            if(input['type'] == 'email') {
                                var regex = new RegExp(regex_email);
                                var resultat = regex.test(value);
                            }
                            if(input['type'] == 'tel') {
                                var regex = new RegExp(regex_telephone);
                                var resultat = regex.test(value);
                            }

                            if(resultat) {
                                if(debug_mode) { console.log('Checked '+input_id+' : VALID'); }
                            } else {
                                if(debug_mode) { console.log('Checked '+input_id+' : NOT VALID'); }
                                if(value != '') {
                                    nb_errors++;
                                    if(list_errors == '') {
                                        list_errors = input['display_name'];
                                    } else {
                                        list_errors += ', '+input['display_name'];
                                    }
                                }
                            }
                        }
                    });

                    if(debug_mode) { console.log('Nombre d\'erreurs : '+nb_errors); }
                    if(debug_mode) { console.log('Nombre de requis vides : '+nb_empty_required); }


                }
            });
        }
        contactform_check_values();


        // Si vérification des champs ratée, afficher erreur, sinon, envoyer la requête AJAX au script PHP
        error_box.find('p').html('');
        if(nb_errors != 0 || nb_empty_required != 0) {
            if(debug_mode) { console.log('La soumission du formulaire a été annulée car le formulaire comporte des erreurs.'); }
            
            if(list_empty_required != '') {
                error_box.find('.form_modal_value_empty').html('Les champs suivants doivent être remplis : '+list_empty_required);
            }
            if(list_errors != '') {
                error_box.find('.form_modal_value_error').html('Les champs suivants comportent des erreurs : '+list_errors);
            }
            
            error_box.modal('toggle');
        
        // Vérification des champs passée, envoyer le formulaire
        } else {
            if(debug_mode) { console.log('INPUT VALIDATION PASSED, sending to PHP...') }
            $form_object.find('.loader').removeClass('hidden');
            
            // Appel ReCaptcha et ajout de la réponse ReCaptcha aux datas
            grecaptcha.ready(function() {
                grecaptcha.execute(grc_site_key, {action: 'submit_contact_form'}).then(function(token) {

                    // Une fois le token récupéré, ajout aux datas et appel ajax
                    data.push({name: 'recaptcha_token', value: token});

                    // Envoi de la requête HTTP en mode asynchrone
                    $.ajax({
                        url: $form_object.attr('action'), // Le nom du fichier indiqué dans le formulaire
                        type: $form_object.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
                        dataType: 'html',
                        data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
                        error: function (request, error) {
                            if(debug_mode) {
                                console.log('--- Ajax error ---');
                                console.log(request);
                                console.log(error);
                            }
                        },
                        success: function(resultat) { // Je récupère la réponse du fichier PHP
                            json_response = JSON.parse(resultat);
                            $form_object.find('.loader').addClass('hidden');

                            if(debug_mode) {
                                console.log('--- Ajax success ---');
                                console.log('Résultat brut :');
                                console.log(resultat);
                                console.log('Reponse JSON :');
                                console.log(json_response);
                            }

                            // Si l'ajout en BDD et l'envoi du mail aux admins est un succès
                            error_box.find('p').html('');
                            if(json_response['sql_success'] && json_response['mail_admin_success']) {
                                if(typeof success_redirect_to !== 'undefined' && success_redirect_to != '' && success_redirect_to != 0) {
                                    target_url = window.location.protocol+'//'+window.location.hostname+success_redirect_to;
                                    window.location.href = target_url;
                                } else {
                                    error_box.find('.form_modal_ok').html('<i class="far fa-check-circle"></i>'+success_message);
                                    error_box.modal('toggle');
                                }
                            } else {
                                error_box.find('.form_modal_fail').html('<i class="fas fa-times"></i>'+fail_message);
                                error_box.find('.errors_list').html(json_response['errors_html']);
                                error_box.modal('toggle');
                            }
                        }
                    });
                });
            });
            
        }
    });
    // ------------------------------ / SOUMISSION DU FORMULAIRE ------------------------------


    // ------------------------------ VERIFICATION DES CHAMPS ------------------------------

    $('.form_contact .form-control').focusout(function() {

        // Récupération de l'id du formulaire
        var form_object = $(this).closest('form');
        var form_id = form_object.attr('id');
        var form_submit = form_object.find('.submit');

        // Récuperation des options sur la vérification des champs
        contact_forms.forEach(function(contact_form) {
            if(contact_form['id'] == form_id) {
                instant_input_validation = contact_form['instant_input_validation'];
                validation_lock_submit = contact_form['validation_lock_submit'];
                green_when_right = contact_form['green_when_right'];
                red_when_wrong = contact_form['red_when_wrong'];
            }
        });

        if(instant_input_validation == 1) {
            
            $(this).removeClass('green');
            $(this).removeClass('red');
            $(this).removeClass('regex');

            texte = $(this).val();
            type = $(this).attr('type');

            var regex_texte = '.+';
            var regex_email = '^.+@.+\\..+';
            var regex_telephone = '^\\+?\\s*(\\d+\\s?){8,}$';

            // Si l'input est requis et vide, nb_empty + 1
            if(value == '' && input['required'] == 1) {
                nb_empty_required++; 
                if(list_empty_required == '') {
                    list_empty_required = input['display_name'];
                } else {
                    list_empty_required += ', '+input['display_name'];
                }
            }

            if(type == 'text' || type == 'textarea') {
                var regex = new RegExp(regex_texte);
                var resultat = regex.test(texte);
            }
            if(type == 'email') {
                var regex = new RegExp(regex_email);
                var resultat = regex.test(texte);
            }
            if(type == 'tel') {
                var regex = new RegExp(regex_telephone);
                var resultat = regex.test(texte);
            }

            if(type != 'select' && type != 'checkbox') {
                if(debug_mode) { console.log('Vérification du champs de type '+type+' : '+texte); }
                if(debug_mode) { console.log('Regex : '+resultat); }

                if(resultat) {
                    if(green_when_right == true) {
                        $(this).addClass('green');
                    }
                } else {
                    $(this).addClass('regex');
                    if(red_when_wrong == true) {
                        $(this).addClass('red');
                    }
                }

                // Comptage du nombre d'erreurs regex et du nombre de champs vides
                regex_error_number = 0;
                empty_input = 0;
                $(form_object).find('.form-control').each(function(){
                    if($(this).hasClass('regex')) { regex_error_number++; }
                    if($(this).val() == '') { empty_input++; }
                });
                nb_errors = regex_error_number + empty_input;
                console.log('Nombre de champs avec erreur : '+regex_error_number);
                console.log('Nombre de champs vides : '+empty_input);

                // Si il n'y a pas d'erreur, activation du bouton submit, si il y a des erreurs, désactivation (SI lock submit)
                if(validation_lock_submit == 1) {
                    if(nb_errors == 0) {
                        $(form_submit).removeAttr('disabled');
                        $(form_submit).removeClass('disabled');
                        console.log('Bouton submit activé');
                    } else {
                        $(form_submit).attr('disabled','disabled');
                        console.log('Bouton submit désactivé');
                        $(form_submit).addClass('disabled');
                    }
                }

            }
        }

    });

    // ------------------------------ / VERIFICATION DES CHAMPS ------------------------------

	// FIN FORMULAIRE AJAX : Contact

	// Fermeture des lightbox de confirmation message
	/*$(".confirmation_envoi_mail .lightbox_close, .confirmation_envoi_mail .fond_lightbox_confirm").click(function() {
	  document.getElementById('confirmation_envoi_mail_ok').style.display = 'none';
	  document.getElementById('confirmation_envoi_mail_fail').style.display = 'none';
	});*/

});