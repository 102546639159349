

// RESULTS FILTERS
$(document).ready(function() {

	// --------- FONCTIONS ---------

	function reorderTable(table, ref_element, inverse) {
		var inverse = (typeof inverse !== 'undefined') ? inverse : false;

		ref_element.filter(function(){
	        return $(this).index() === 0;
	    }).sortElements(function(a, b){
	        return parseInt($.text([a])) > parseInt($.text([b])) ?
	            inverse ? -1 : 1
	            : inverse ? 1 : -1;
	    }, function(){
	        // parentNode is the element we want to move
	        return this.parentNode.parentNode; 
	    });
	    inverse = !inverse;
	}

	// Detach toutes les lignes du tableau de l'épreuve courante sauf la première (head)
	function detachResults(race) {
	    $('.results_epreuve.epreuve_'+race+' .table-row').each(function(index) {
			if(index != 0) { $(this).detach(); }
		});
	}

	// Ré-attacher les résultats de la catégorie selectionnée pour la course selectionnée en tenant compte du point actuel
	function attachResults(race_id, category_name, point_id) {
		current_search_string = $('.results_epreuve.epreuve_'+race_id+' .current_search_string').html();
		current_search_string = RemoveAccents(current_search_string.toLowerCase());
		if(debug_mode == true) {
			console.log('SEARCH STRING : '+current_search_string);
		}

	    nb_results = 0;
	    all_results.forEach(function(race_result) {
			if(race_result['race'] == race_id) {
				race_result['categories'].forEach(function(category) {
					if(category['cat'] == category_name || category_name == 'all') {
						category['points'].forEach(function(point) {
							if(point['point'] == point_id) {
								point['lines'].forEach(function(line) {
									if(current_search_string == 'all' || line['name'].indexOf(current_search_string) != -1 || line['bib'].indexOf(current_search_string) != -1) {
										$('.results_epreuve.epreuve_'+race_id+' .htmltable.results_list').append(line['dom_objects']);
										nb_results++;
									}
								});
							}
						});
					}
				});
			}
		});

		if(nb_results == 0) {
			$('.results_epreuve.epreuve_'+race_id+' .no_results_found').removeClass('hidden');
		} else {
			$('.results_epreuve.epreuve_'+race_id+' .no_results_found').addClass('hidden');
		}

		if ($(".no_results_found.php").length !== 0){
		    $('.results_epreuve.epreuve_'+race_id+' .no_results_found.js').addClass('hidden');
		}
	}

	// --------- /FONCTIONS ---------


	// DÉTERMINATION DES POINTS (DE MESURE DE TEMPS) À UTILISER
	// Généré par PHP
	if(debug_mode == true) {
		console.log('Points :'); 
		console.log(points_infos);
	}


	// RÉCUPÉRATION DE TOUS LES RÉSULTATS TRIÉS PAR ÉPREUVE PUIS PAR CATÉGORIE
    all_results = [];
    $('.select_categorie').each(function(index) {
		race = $(this).data('race');

		categories = [];
		$(this).find('option').each(function() {
	    	category = $(this).val();

	    	if(category != '') {
		    	points = [];
		    	points_infos[race].forEach(function(point) {

		    		lines = [];
		    		$('.results_epreuve.epreuve_'+race+' .table-row[data-cat='+category+'][data-point='+point+']').each(function() {
		    			name = $(this).find('.lineinfo_name').html();
		    			name = RemoveAccents(name.toLowerCase());
		    			bib = $(this).find('.lineinfo_bib').html();

		    			lines.push({
							name: name,
							bib: bib,
							dom_objects: $(this)
						});
		    		});

					points.push({
						point: point,
						lines: lines
					});
				});

		        categories.push({
					cat: category,
					points: points
				});
		    }
		});

		all_results.push({
			race: race,
			categories: categories
		});
	});
	if(debug_mode == true) {
		console.log('All results :');
		console.log(all_results);
	}


	// INITIALISATION (boucle qui passe par toutes les épreuves)
	all_results.forEach(function(race) {
		race = race['race'];
		displayed_point = points_infos[race][0];
		point_name = $('.epreuve_'+race+' .filtres_results .step.active').data('pointname');
		/*active_point = $('.epreuve_'+race+' .filtres_results .step.active').data('point');
	    if(active_point > 0) {
	    	currently_displayed_point = active_point;
	    } else {
	    	currently_displayed_point = points_infos[race][0];
	    }*/

		detachResults(race);
		if(race == init_epreuve) {
			attachResults(race, init_cat, init_displayed_point);
			// Changement intitulé sur mobile
			$('.epreuve_'+race+' .rappel_point').html(point_name);
		} else {
			attachResults(race, 'all', displayed_point);
		}
		table = $('.epreuve_'+race+' .htmltable');
		ref_element = $('.epreuve_'+race+' .htmltable .classement .display_rank_global');
		reorderTable(table, ref_element);
	});
	$('.loader').addClass('hidden');
	$('.table_container').removeClass('loading');

	
	// SELECTION EPREUVE
	$('.select_epreuve').change(function(){
	    epreuve_id = $(this).val();

	    // Afficher le bon tableau de résultats
	    $('.results_epreuve').addClass('hidden');
	    $('.epreuve_'+epreuve_id).removeClass('hidden');

	    // Déterminer les paramètres des autres filtres
	    current_cat = $('.filtres_results .select_categorie.race_'+epreuve_id).val();
	    current_point = $('.results_epreuve.epreuve_'+epreuve_id+' .step.active').data('point');
	    if(current_cat === undefined) { current_cat = 'all'; }
	    if(current_point === undefined) { current_point = 1; }

	    // Close popover
		$("[data-toggle=popover]").popover('hide');
	    
	    updateQueryStringParam('epreuve', epreuve_id);
	    updateQueryStringParam('cat', current_cat);
	    updateQueryStringParam('point', current_point);
	});


	// FILTRE POINT
	$('.filtres_results .step').click(function(){
	    epreuve_id = $(this).data('race');
	    point = $(this).data('point');
	    pointname = $(this).data('pointname');
	    category = $('.filtres_results .select_categorie.race_'+epreuve_id).val();

	    if(debug_mode == true) {
		    console.log('epreuve_id : '+epreuve_id);
		    console.log('point : '+point);
		    console.log('category : '+category);
		}

	    detachResults(epreuve_id);
	    attachResults(epreuve_id, category, point);
	    // Si on vient de réafficher toutes les catégories, trier le tableau
		table = $('.epreuve_'+epreuve_id+' .htmltable');
		ref_element = $('.epreuve_'+epreuve_id+' .htmltable .classement .display_rank_global');
		reorderTable(table, ref_element);

	    // Modifier affichage boutons en fonction du nouveau point choisi
	    $('.results_epreuve.epreuve_'+epreuve_id+' .step').removeClass('active');
	    $('.results_epreuve.epreuve_'+epreuve_id+' .step[data-point='+point+']').addClass('active');

	    // Afficher les bons ranks en fonction de la situation
		if(category == 'all') {
			$('.results_epreuve.epreuve_'+epreuve_id+' .display_rank_global').removeClass('hidden');
			$('.results_epreuve.epreuve_'+epreuve_id+' .display_rank_cat').addClass('hidden');
		} else {
			$('.results_epreuve.epreuve_'+epreuve_id+' .display_rank_global').addClass('hidden');
			$('.results_epreuve.epreuve_'+epreuve_id+' .display_rank_cat').removeClass('hidden');
		}
	    
	    updateQueryStringParam('point', point);

	    // Show reset button
		$('.epreuve_'+epreuve_id+' .clean_filters').addClass('active');
		// Close mobile filter menu
		$('.filtres_results').removeClass('active');
		// Changement intitulé sur mobile
		point_name = $('.epreuve_'+epreuve_id+' .filtres_results .step.active').data('point_name');
		$('.epreuve_'+epreuve_id+' .rappel_point').html(pointname);
		// Close popover
		$("[data-toggle=popover]").popover('hide');
	});


	// FILTRE CATÉGORIE
	$('.select_categorie').change(function(){
	    current_race = $(this).data('race');
	    selected_category = $(this).val();

	    active_point = $('.epreuve_'+current_race+' .filtres_results .step.active').data('point');

	    if(active_point > 0) {
	    	currently_displayed_point = active_point;
	    } else {
	    	currently_displayed_point = higher_race_points[current_race];
	    }
	    

	    if(debug_mode == true) {
		    console.log('Current race : '+current_race);
		    console.log('Selected category : '+selected_category);
		    console.log('Currently displayed point : '+currently_displayed_point);
		}

	    // Detach toutes les lignes du tableau de l'épreuve courante
	    detachResults(current_race);

	    // Ré-attacher les résultats de la catégorie selectionnée pour la course selectionnée en tenant compte du point actuel
	    attachResults(current_race, selected_category, currently_displayed_point);
		
		// Si on vient de réafficher toutes les catégories, trier le tableau
		table = $('.epreuve_'+current_race+' .htmltable');
		ref_element = $('.epreuve_'+current_race+' .htmltable .classement .display_rank_global');
		reorderTable(table, ref_element);

		// Afficher les bons ranks en fonction de la situation
		if(selected_category == 'all') {
			$('.epreuve_'+current_race+' .display_rank_global').removeClass('hidden');
			$('.epreuve_'+current_race+' .display_rank_cat').addClass('hidden');
		} else {
			$('.epreuve_'+current_race+' .display_rank_global').addClass('hidden');
			$('.epreuve_'+current_race+' .display_rank_cat').removeClass('hidden');
		}
	    
	    updateQueryStringParam('cat', selected_category);

	    // Show reset button
		$('.epreuve_'+current_race+' .clean_filters').addClass('active');
		// Close mobile filter menu
		$('.filtres_results').removeClass('active');
		// Close popover
		$("[data-toggle=popover]").popover('hide');
	});


	// FILTRE RECHERCHER
	$(document).on("click",".search_submit",function(){ 
		race_id = $(this).closest('.search_form_container').data('race');
		console.log('RACE_ID = '+race_id);
		searchFilter(race_id);
    });
    $(document).on( "keydown", ".search_input", function(event) {
		if(event.which == 13) {
			race_id = $(this).closest('.search_form_container').data('race');
			searchFilter(race_id);
		}
    });
	
	function searchFilter(race_id) {
		search_string = $('.popover .search_input').val();
		console.log('Search string : '+search_string);

		// Modification des éléments relatifs à la recherche
		$('.epreuve_'+race_id+' .current_search_string').html(search_string);
		$('.epreuve_'+race_id+' .search_input').attr('value', search_string);
		$('.popover').popover('hide');

		// Mise à jour du tableau en fonction de la recherche
		selected_category = $('.filtres_results .select_categorie.race_'+race_id).val();
		active_point = $('.epreuve_'+race_id+' .filtres_results .step.active').data('point');
	    if(active_point > 0) {
	    	currently_displayed_point = active_point;
	    } else {
	    	currently_displayed_point = higher_race_points[race_id];
	    }
	    if(debug_mode == true) {
		    console.log('Current race : '+race_id);
		    console.log('Selected category : '+selected_category);
		    console.log('Currently displayed point : '+currently_displayed_point);
		}
		detachResults(race_id);
		attachResults(race_id, selected_category, currently_displayed_point);
		table = $('.epreuve_'+race_id+' .htmltable');
		ref_element = $('.epreuve_'+race_id+' .htmltable .classement .display_rank_global');
		reorderTable(table, ref_element);

		// Show reset button
		$('.epreuve_'+race_id+' .clean_search').addClass('active');
		$('.epreuve_'+race_id+' .clean_filters').addClass('active');
		// Close mobile filter menu
		$('.filtres_results').removeClass('active');
	}


	// RESET FILTERS
	$('.clean_filters').click(function(){
		race_id = $(this).parent().parent().data('race');

		// Reset interface
		$('.results_epreuve.epreuve_'+race_id+' .step').removeClass('active');
	    $('.results_epreuve.epreuve_'+race_id+' .step.race_end').addClass('active');
	    $('.select_categorie option[value="all"]').prop('selected', true);

	    // Reset get parameters
	    point = $('.results_epreuve.epreuve_'+race_id+' .step.race_end').data('point');
	    updateQueryStringParam('cat', 'all');
	    updateQueryStringParam('point', point);

	    // Reset search
	    $('.epreuve_'+race_id+' .current_search_string').html('all');
		$('.epreuve_'+race_id+' .search_input').attr('value', '');
		$('.popover').popover('hide');

	    // Reset table
		detachResults(race_id);
		attachResults(race_id, 'all', point);
		table = $('.epreuve_'+race_id+' .htmltable');
		ref_element = $('.epreuve_'+race_id+' .htmltable .classement .display_rank_global');
		reorderTable(table, ref_element);

		// Hide reset button
		$('.results_epreuve.epreuve_'+race_id+' .clean_filters').removeClass('active');
	});

	// RESET SEARCH
	$('.clean_search').click(function(){
		race_id = $(this).parent().parent().data('race');

	    // Reset search
	    $('.epreuve_'+race_id+' .current_search_string').html('all');
		$('.epreuve_'+race_id+' .search_input').attr('value', '');
		$('.popover').popover('hide');

	    // Reset table
	    selected_category = $('.filtres_results .select_categorie.race_'+race_id).val();
	    active_point = $('.epreuve_'+race_id+' .filtres_results .step.active').data('point');
	    if(active_point > 0) {
	    	currently_displayed_point = active_point;
	    } else {
	    	currently_displayed_point = higher_race_points[race_id];
	    }
		detachResults(race_id);
		attachResults(race_id, selected_category, active_point);
		table = $('.epreuve_'+race_id+' .htmltable');
		ref_element = $('.epreuve_'+race_id+' .htmltable .classement .display_rank_global');
		reorderTable(table, ref_element);

		// Hide reset button
		$('.results_epreuve.epreuve_'+race_id+' .clean_search').removeClass('active');
	});


	// BOUTON FILTERS SUR MOBILE
	$('.bouton_filters').click(function(){
		if($('.filtres_results').hasClass('active')) {
			$('.filtres_results').removeClass('active');
		} else {
			$('.filtres_results').addClass('active');
		}
	});

});






/**
 * jQuery.fn.sortElements
 * --------------
 * @author James Padolsey (http://james.padolsey.com)
 * @version 0.11
 * @updated 18-MAR-2010
 * --------------
 * @param Function comparator:
 *   Exactly the same behaviour as [1,2,3].sort(comparator)
 *   
 * @param Function getSortable
 *   A function that should return the element that is
 *   to be sorted. The comparator will run on the
 *   current collection, but you may want the actual
 *   resulting sort to occur on a parent or another
 *   associated element.
 *   
 *   E.g. $('td').sortElements(comparator, function(){
 *      return this.parentNode; 
 *   })
 *   
 *   The <td>'s parent (<tr>) will be sorted instead
 *   of the <td> itself.
 */
jQuery.fn.sortElements = (function(){
    
    var sort = [].sort;
    
    return function(comparator, getSortable) {
        
        getSortable = getSortable || function(){return this;};
        
        var placements = this.map(function(){
            
            var sortElement = getSortable.call(this),
                parentNode = sortElement.parentNode,
                
                // Since the element itself will change position, we have
                // to have some way of storing it's original position in
                // the DOM. The easiest way is to have a 'flag' node:
                nextSibling = parentNode.insertBefore(
                    document.createTextNode(''),
                    sortElement.nextSibling
                );
            
            return function() {
                
                if (parentNode === this) {
                    throw new Error(
                        "You can't sort elements if any one is a descendant of another."
                    );
                }
                
                // Insert before flag:
                parentNode.insertBefore(this, nextSibling);
                // Remove flag:
                parentNode.removeChild(nextSibling);
                
            };
            
        });
       
        return sort.call(this, comparator).each(function(i){
            placements[i].call(getSortable.call(this));
        });
        
    };
    
})();