
$(document).ready(function() {
	if (undefined == $.cookie('cookies_acceptance'))
	$('#cookie_notice').removeClass('hide');
});

$('#cookie_notice .accept_cookies_button').click(function() {
	$.cookie(
		'cookies_acceptance',
		'ok', {
		  expires: 365,
		  path: '/'
		}
	);

	$('#cookie_notice').addClass('hide');
});